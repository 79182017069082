/* ======================= Featured Contributor Section Css Start ================== */
.featured-contributor {
    &::before {
        position: absolute;
        content: "";
        background: linear-gradient(152deg, rgba(246, 246, 246, 0.20) 17.12%, rgba(94, 53, 242, 0.20) 105.91%);
        filter: blur(100px);
        width: 752px;
        height: 614px;
        right: -224px;
        bottom: 70px;
    }
}

.contributor-item {
    background-color: hsl(var(--black));
    border-radius: 16px;
    padding: 8px;
    overflow: hidden;
    position: relative;
    &:hover {
        box-shadow: 0px 20px 30px 0px rgba(197, 196, 201, 0.25);
        .contributor-item {
            &__thumb {
                &::before {
                    height: 100%;
                    visibility: visible;
                    opacity: 1;
                    z-index: 1;
                }
                img {
                    transform: scale(1.1);
                }
            }
            &__link {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &__thumb {
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 0%;
            left: 0;
            bottom: 0;
            border-radius: 8px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 4, 37, 0.50) 69.5%, rgba(0, 4, 37, 0.50) 100%);
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            transition: .2s linear;
        }
        img {
            transition: .2s linear;
        }
    }
    &__link {
        position: absolute;
        z-index: 1;
        right: 16px;
        bottom: 16px;
        visibility: hidden;
        opacity: 0;
        transition: .2s linear;
        &:hover {
            transform: scale(1.2);
        }
    }
    &__badge {
        position: absolute;
        right: 24px;
        top: 0;
    }
}

.contributor-info {
    padding: 12px 8px;
    padding-bottom: 20px;
    &__thumb {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: hsl(var(--white));
    }
}
/* ======================= Featured Contributor Section Css Start ================== */