/* ======================== Service Section Css Start ========================== */
.service {
    background-color: #F3EBFF;
}

.service-item {
    border-radius: 16px; 
    background-color: hsl(var(--white));
    overflow: hidden;
    padding: 32px;
    transition: .2s linear;
    position: relative;
    z-index: 1;
    height: 100%;
    &:hover {
        background-color: hsl(var(--black));
        .hover-bg {
            visibility: visible;
            opacity: 1;
        }
        .service-item {
            &__title {
                color: hsl(var(--static-white));
            }
            &__desc {
                color: hsl(var(--static-white));
            }
        }
        .btn-simple {
            color: hsl(var(--static-white));
        }
    }
    @include lg-screen {
        padding: 24px;
    }
    @include md-screen {
        padding: 24px 16px;
    }

    &__icon {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #F5F7F9;
        @include msm-screen {
            width: 60px;
            height: 60px;
        }
    }   
    &__title {
        transition: .2s linear;
    }
    &__desc {
        margin-bottom: 24px;
        transition: .2s linear;
        @include md-screen {
            margin-bottom: 16px;
        }
    }
}

.hover-bg-main {
    position: relative;
    z-index: 1;
    &:hover {
        .hover-bg {
            visibility: visible;
            opacity: 1;
        }
    }
}
.hover-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: .2s linear; 
    z-index: -1;
}


.btn-simple {
    display: flex;
    align-items: center;
    gap: 8px;
    color: hsl(var(--heading-color));
    font-weight: 500;
    &:hover {
        color: hsl(var(--main));
        .icon {
            margin-left: 8px;
        }
    }
    .icon {
        transition: .2s linear;
    }
}

/* ======================== Service Section Css End ========================== */