/* =========================== Blog Section Css Start ========================== */
.post-item {
    background-color: hsl(var(--white));
    border-radius: 16px;;
    overflow: hidden;
    height: 100%;
    &:hover {
        .post-item {
            &__thumb {
                img {
                    transform: scale(1);
                }
            }
        }
    }
    &__thumb {
        max-height: 294px;
        overflow: hidden;
         img {
            transform: scale(1.1);
            transition: .2s linear;
        }
    }
    &__content {
        padding: clampCal(16, 24);
    }
    &__top {
        gap: clampCal(16, 24, 1199, 1399);
    }
    &__tag {
        padding: 8px 16px;
        background-color:#F5F7F9;
    }
    &__title {
        margin: 24px 0 32px;
        .link {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;  
            overflow: hidden;
        }
    }
}
/* =========================== Blog Section Css End ========================== */