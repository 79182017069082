/* ================= Common Card Css Start ========================= */
.common-card {
    border: 0;
    box-shadow: var(--box-shadow); 
    border-radius: 16px;
    background-color: hsl(var(--white));
    .card-header, .card-footer {
        padding: 12px 24px;
        background-color: transparent;
        border-bottom: 1px solid hsl(var(--border-color));
        @include xsm-screen {
            padding: 12px 16px;
        }
    }
    .card-header {
        background-color: hsl(var(--black));
        .title {
            color: hsl(var(--static-white));
            margin-bottom: 0;
        }
    }
    .card-body {
        padding: 24px;
        @include xsm-screen {
            padding: 16px;
        }
    }
    .card-footer {
        border-top: 1px solid hsl(var(--border-color));
        border-bottom: 0;
    }
}
/* ================= Common Card Css End ========================= */