/* ============================ Sale Offer Css Start =========================== */
.sale-offer {
    background: linear-gradient(117deg, #FEF0F8 14.35%, #DACFFF 84.4%);
    padding: 8px 0;
    &__content {
        gap: 16px;
        @include xsm-screen {
            gap: 8px;
        }
    }
    &__text {
        font-size: clampCal(11, 14)
    }
    &__qty {
        font-size: clampCal(12, 18);
    }
    &__button {
        width: 32px;
    }
    &__close {
        position: absolute;
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        z-index: 1;
        transition: .2s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        @include xsm-screen {
            width: 28px;
            height: 28px;
        }
        &:hover {
            color: hsl(var(--main));
            &::before {
                visibility: visible;
                opacity: 1;
                transform: scale(1);
            }
        }
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: hsl(var(--main)/.2);
            z-index: -1;
            border-radius: inherit;
            transition: .2s linear;
            visibility: hidden;
            opacity: 0;
            transform: scale(.8);
        }
    }
}

.countdown {
    display: flex;
    align-items: center;
    gap: 16px;
    @include xsm-screen {
        gap: 10px;
    }
    > div {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-direction: row-reverse;
    }
    
    span {
        font-weight: 500;
        color: hsl(var(--heading-color));
        font-size: 14px;
    }
}
/* ============================ Sale Offer Css End =========================== */