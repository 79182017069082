/* ======================= Selling Products css Start ========================= */
.selling-product {
    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 62%;
        background-color: hsl(var(--black));
        left: 0;
        top: 0;
        z-index: -1;
    }
    .bg--gradient {
        @media (min-width: 575px) {
            top: 0;
            transform: unset;
            height: 62%;
        }
    }
    .slick-arrow {
        top: 56%;
    }
}
/* ======================= Selling Products css End ========================= */