
/* ============================ Extend Css Start ======================== */
/* Cover image Css */
.cover-img {
    width: 100%;
    height: 100%;
    object-fit: cover;  
}
/* Display Flex Css Start */ 
  .flx-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .flx-align {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .flx-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .flx-between {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
/* Display Flex Css End */

/* Positioning Css Class Start */
.pa-content {
    position: absolute;
    content: "";
}
.top-center {
    top: 50%;
    transform: translateY(-50%);
}
.left-center {
    left: 50%;
    transform: translateX(-50%);
}
.top-left-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* Positioning Css Class End */

/* Font Size For responsive devices Start */
.font-10 {
    font-size: 0.625rem !important;
}
.font-11 {
    font-size: 0.6875rem !important;
}
.font-12 {
    font-size: 0.75rem !important;
}
.font-13 {
    font-size: 0.8125rem !important;
}
.font-14 {
    font-size: 0.875rem !important;
}
.font-15 {
    font-size: 0.9375rem !important;
}
.font-16 {
    font-size: 1rem !important;
}
.font-17 {
    font-size: 1.0625rem !important;
}
.font-18 {
    font-size: 1.125rem !important;
    @include lg-screen {
        font-size: 1rem !important;
    }
    @include sm-screen {
        font-size: 0.9375rem !important;
    }
}
.font-19 {
    font-size: 1.1875rem !important;
}
.font-20 {
    font-size: 1.25rem !important;
    @include lg-screen {
        font-size: 1.125rem !important;
    }
    @include sm-screen {
        font-size: 1rem !important;
    }
}
.font-21 {
    font-size: 1.3125rem !important;
}
.font-22 {
    font-size: 1.375rem !important;
}
.font-23 {
    font-size: 1.4375rem !important;
}
.font-24 {
    font-size: 1.5rem !important;
}
.font-25 {
    font-size: 1.5625rem !important;
}
.font-26 {
    font-size: 1.625rem !important;
}
.font-27 {
    font-size: 1.6875rem !important;
}
.font-28 {
    font-size: 1.75rem !important;
}
.font-29 {
    font-size: 1.8125rem !important;
}
.font-30 {
    font-size: 1.875rem !important;
}
.font-31 {
    font-size: 1.9375rem !important;
}
.font-32 {
    font-size: 2rem !important;
}
.font-33 {
    font-size: 2.0625rem !important;
}
.font-34 {
    font-size: 2.125rem !important;
}
.font-35 {
    font-size: 2.1875rem !important;
}
.font-36 {
    font-size: 2.25rem !important;
}
.font-37 {
    font-size: 2.3125rem !important;
}
.font-38 {
    font-size: 2.375rem !important;
}
.font-39 {
    font-size: 2.4375rem !important;
}
.font-40 {
    font-size: 2.5rem !important;
}
/* Font Size For responsive devices End */

/* ============================ Extend Css End ======================== */

