/* ================================= preload Css Start =========================== */

.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsl(var(--white));
  z-index: 99999;
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80px;
    height: 80px;
    font-size: 0;
    display: inline-block;
    margin: -40px 0 0 -40px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    div {
      background-color: hsl(var(--main));
      display: inline-block;
      float: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
      opacity: 0.6;
      border-radius: 50%;
      -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
      animation: ballPulseDouble 2s ease-in-out infinite;
      &:last-child {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }
  }
}

@-webkit-keyframes ballPulseDouble {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ballPulseDouble {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* ================================= preload Css End ===========================  */
