/* ========================== Hidden Item Css Start =========================== */
.product-reject__wrapper {
    > div {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.product-reviewer {
    &__thumb {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        overflow: hidden;
    }
}

.product-reject-info {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid hsl(var(--gray-five));
    &__thumb {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        overflow: hidden;
    }
}

.response-list {
    &__item {
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
/* ========================== Hidden Item Css End =========================== */