/* ============================== Banner Two Css Start =========================== */
.banner-two {
    background-color: hsl(var(--gray-seven));
    padding: 160px 0;
    position: relative;
    @include lg-screen {
        padding: 120px 0;
    }
    @include md-screen {
        padding: 100px 0;
    }
    @include sm-screen {
        padding: 80px 0;
    }
    &__content {
        max-width: 880px;
        margin: 0 auto;
    }
    &__title {
        @media (min-width: 992px) and (max-width: 1299px) {
            font-size: 60px;
        }
    }
    &__desc {
        margin-bottom: 32px;
        max-width: 590px;
        margin-left: auto;
        margin-right: auto;
        @include lg-screen {
            margin-bottom: 24px;
        }
    }
}

.popular-search {
    margin-top: clampCal(24, 64);
}

.search-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    &__link {
        padding: 3px 10px;
        border-radius: 50px;
        border: 1px solid hsl(var(--border-color));
        outline: 1px solid transparent;
        &:hover {
            color: hsl(var(--main)) !important;
            font-weight: 600;
            border-color: hsl(var(--main));
            background-color: hsl(var(--main)/.08);
            outline-color: hsl(var(--main));
        }
    }
}

/* Statistics */
.statistics-wrapper {
    display: flex;
    align-items: center;
    gap: 36px;
    @include xl-screen {
        display: inline-grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 24px;
    }
    @include sm-screen {
        gap: 14px;
    }
    &.style-right {
        .statistics {
            &:nth-child(1) {
                @media (min-width: 1200px) {
                    margin-top: 96px;
                }
            }
            &:nth-child(2) {
                margin-top: 0px;
            }
        }
    }
    .statistics {
        &:nth-child(2) {
            @media (min-width: 1200px) {
                margin-top: 96px;
            }
        }
    }
}

/* ============================== Banner Two Css End =========================== */