/* ======================= To Featured Author Css Start =============================== */
.top-author {
  .spider-net {
    left: -18% !important;
  }
}

.follow-btn.active {
  background-color: hsl(var(--main)) !important;
  color: hsl(var(--static-white)) !important;
}
.circle-content {
  @media (min-width: 575px) {
    margin-left: 80px;
  }
}

/* text Circle Rotation */
.circle {
  position: relative;
  margin-bottom: 24px;
  width: 158px;
  height: 158px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(var(--white) / 0.5);
  backdrop-filter: blur(6px);
  z-index: 21;
  border: 1px solid hsl(var(--white));

  @media (min-width: 575px) {
    top: 64px;
    left: -80px;
    position: absolute;
  }

  &.style-two {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;
      position: absolute !important; 
      @include msm-screen {
        width: 120px;
        height: 120px;
      }
    .circle {
      &__text span {
        transform-origin: 0 76px;
        @include msm-screen {
          font-size: 14px;
        }
        @include msm-screen {
          transform-origin: 0 58px;
        }
      }
      &__badge {
        border-color: hsl(var(--white));
      } 
    }
  }

  &.static-circle {
    border-color: hsl(var(--static-white));
    border-color: hsl(var(--static-white));
    background-color: hsl(var(--static-white)/0.5);
    .circle__badge {
      border-color: hsl(var(--static-white));
    }
    .circle__text {
      color: hsl(var(--static-black)) !important;
    }
    
    .circle__text span {
        color: hsl(var(--static-black));
    }
  }

  &__badge {
    border: 1px solid hsl(var(--main));
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
  }
  &__text {
    position: absolute;
    width: 100%;
    height: 100%;
    color: hsl(var(--heading-color));
    font-size: 1rem;
    animation: textRotation 8s linear infinite;
    span {
      position: absolute;
      left: 50%;
      font-size: 1rem;
      font-family: "inter";
      transform-origin: 0 78px;
      color: hsl(var(--heading-color));
      font-weight: 500;
    }
  }
}

@keyframes textRotation {
  to {
    transform: rotate(360deg);
  }
}
/* text Circle Rotation */
/* ======================= To Featured Author Css End =============================== */
