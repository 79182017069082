/* ============================ Popular Item Section Start =========================== */
.popular-item-card-section {
    position: relative;
    z-index: 1;
    &::before {
        position: absolute;
        content: "";
        width: 675px;
        height: 552px;
        background: linear-gradient(152deg, rgba(246, 246, 246, 0.20) 17.12%, rgba(94, 53, 242, 0.20) 105.91%);
        filter: blur(100px);
        left: 0;
        bottom: 200px;
        z-index: -1;
    }
}

.popular-item-card {
    background-color: hsl(var(--white));
    padding: 20px;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 5px 20px 0px rgba(189, 190, 204, 0.4);
    &:hover {
        box-shadow: 0px 20px 30px 0px rgba(197, 196, 201, 0.25);
        .product-item {
            &__bottom {
                visibility: visible;
                opacity: 1;
            }
        }
        .popular-item-card {
            &__thumb {
                &::before {
                    height: 100%;
                    visibility: visible;
                    opacity: 1;
                    z-index: 1;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &__thumb {
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        z-index: 1;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 0%;
            left: 0;
            bottom: 0;
            border-radius: 8px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 4, 37, 0.50) 69.5%, rgba(0, 4, 37, 0.50) 100%);
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            transition: .2s linear;
        }
        img {
            transition: .2s linear;
        }
    }

    .product-item {
        &__bottom {
            position: absolute;
            bottom: 0;
            color: hsl(var(--white));
            z-index: 2;
            width: 100%;
            padding: 16px;
            padding-top: 0;
            margin-top: 0;
            border-top: 0;
            visibility: hidden;
            opacity: 0;
            transition: .2s linear;
        }
        &__author {
            color: hsl(var(--white));
        }
    }
    
    &__content {
        margin-top: 24px;
    }
    &__title {
        text-transform: capitalize;
        .link {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }  
}
.btn-link {
    &:hover {
        transform: scale(1.2);
    }
} 
/* ============================ Popular Item Section End =========================== */