
/* ================================= Social Icon Css Start =========================== */
.social-icon-list {
    display: inline-flex;
    gap: 12px;
    align-items: center;
    &__link {
        width: 40px;
        height: 40px;
        border: 1px solid hsl(var(--static-white-one));
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: hsl(var(--static-white));
        position: relative;
        @include font-14; 
        z-index: 1;
        &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            border-radius: inherit;
            background: var(--main-gradient);
            transition: .2s linear;
            z-index: -1;
            visibility: hidden;
            opacity: 0;
        }
        &:hover {
            color: hsl(var(--static-white)) !important;
            border-color: transparent;
            &::after {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
/* ================================= Social Icon Css End ===========================  */