/* ================= Slick Arrow & Dots css Start ================ */ 
.slick-initialized.slick-slider {
    .slick-track {
        display: flex;
    }
    .slick-slide {
      cursor: grab;
        height: auto;
        margin: 0 10px;
        > div {
            height: 100%;
        }
    }
  }

  /* Arrow Small size */
  .arrow-sm {
    .slick-arrow {
      width: 32px;
      height: 32px;
      border: 1px solid hsl(var(--white-one));
      box-shadow: 0px 20px 30px rgba(197, 196, 201, 0.25);
      @include font-18;
      &:hover {
        border-color: hsl(var(--main));
      }
    }
  }
  
  
  /* Slick Slider Arrow */
  .slick-arrow {
    position: absolute;
    z-index: 1;
    border: none;
    background-color: transparent;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: .2s linear;
    bottom: clampCal(24, 60);
    left: clampCal(24, 60);
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    background-color: hsl(var(--white));
    color: hsl(var(--heading-color));
    box-shadow: 0px 20px 30px 0px rgba(197, 196, 201, 0.25);
    @include font-24; 
    left: -24px;
    @include msm-screen {
      left: -10px;
    }
  }
  .slick-arrow {
    &:hover {
      background-color: hsl(var(--main));
      color: hsl(var(--white));
    }
  }
  .slick-next {
    right: -24px;
    left: auto;
    @include msm-screen {
      right: -10px;
    }
  }


  // Arrow Style Two

  // .arrow-style-two {
  //   position: relative;
  //   .slick-arrow {
  //     left: auto;
  //     top: auto;
  //     right: 0;
  //     bottom: calc(100% + 50px);
  //     width: 48px;
  //     height: 48px;
  //     border-radius: 5px;
  //     transform: unset;
  //   }
  //   .slick-prev {
  //     right: 60px;
  //   }
  //   .slick-next {
  //     transform: translateX(0px);
  //   }
  // }

  /* Dots Css Start */ 
  .slick-dots {
      text-align: center;
      margin-top: clampCal(24, 60);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      @include lg-screen {
        gap: 8px;
      }
      li {
          display: inline-block;
          button {
              border: none;
              background-color: transparent;
              color: hsl(var(--white));
              width: 16px;
              height: 16px;
              border-radius: 50%;
              text-indent: -9999px;
              transition: .3s linear;
              border: 1px solid hsl(var(--black));
          }
          &.slick-active button {
              background-color: hsl(var(--black));
          }
      }
  }
  /* Dots Css End */
  /* ================= Slick Arrow & Dots css Start ================ */ 
  