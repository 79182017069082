/* ================================= Margin Css Start =========================== */
.mt--100 {
  margin-top: -100px !important;
}
.mt--24 {
  margin-top: -24px !important;
}


.mb-64 {
  margin-bottom: clampCal(32, 64) !important;
}
.mt-64 {
  margin-top: clampCal(32, 64) !important;
}

.mt-48 {
  margin-top: clampCal(24, 48) !important;
}
.mb-48 {
  margin-bottom: clampCal(24, 48) !important;
}

.mt-40 {
  margin-top: clampCal(24, 40) !important;
}
.mb-40 {
  margin-bottom: clampCal(24, 40) !important;
}

.mt-32 {
  margin-top: 32px !important;
  @include lg-screen {
    margin-top: 20px;
  }
}
.mb-32 {
  margin-bottom: 32px !important;
  @include lg-screen {
    margin-bottom: 20px;
  }
}
.mt-24 {
  margin-top: 24px !important;
  @include lg-screen {
    margin-top: 16px;
  }
}
.mb-24 {
  margin-bottom: 24px !important;
  @include lg-screen {
    margin-bottom: 16px;
  }
}

.margin-y-120 {
  margin-top: 60px;
  margin-bottom: 60px;
  @media (min-width: 576px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @media (min-width: 992px) {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
.margin-t-120 {
    margin-top: 60px;
    @media (min-width: 576px) {
    margin-top: 80px;
  }
    @media (min-width: 992px) {
    margin-top: 120px;
  }
}
.margin-b-120 {
    margin-bottom: 60px;
    @media (min-width: 576px) {
    margin-bottom: 80px;
  }
    @media (min-width: 992px) {
    margin-bottom: 120px;
  }
}
.margin-y-60 {
    margin-top: 30px;
    margin-bottom: 30px;
    @media (min-width: 576px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
    @media (min-width: 992px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.margin-t-60 {
    margin-top: 30px;
    @media (min-width: 576px) {
    margin-top: 40px;
  }
    @media (min-width: 992px) {
    margin-top: 60px;
  }
}
  .margin-b-60 {
    margin-bottom: 30px;
    @media (min-width: 576px) {
    margin-bottom: 40px;
  }
    @media (min-width: 992px) {
    margin-bottom: 60px;
  }
}
/* ================================= Margin Css End =========================== */
