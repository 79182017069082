

/* Mobile Menu Sidebar Start */
.mobile-menu {
    position: fixed;
    background-color: hsl(var(--white));
    width: 300px;
    height: 100vh;
    overflow-y: auto;
    padding: 24px;
    z-index: 991;
    transform: translateX(-100%);
    transition: .2s linear;
    padding-bottom: 68px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #e0e0e0;
    }
    &::-webkit-scrollbar-thumb {
        background: #bab9b9;
        border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #949494;
    }
    &.active {
        transform: translateX(0%);
    }
}
.close-button {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 28px;
    height: 28px;
    background-color: var(--gray-200);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    &:hover {
        background-color: hsl(var(--black));
        color: hsl(var(--white));
    }
}

.nav-menu {
    &--mobile {
        display: block;
        margin-top: 32px;
        .nav-menu {
            &__item {
                > a {
                    &::before {
                        transform: translateY(-50%) rotate(0deg);
                    }
                }
            }
            &__link {
                border-bottom: 1px solid hsl(var(--black)/.08) !important;
                position: relative;
                padding: 12px 0;
                padding-right: 16px;
              
            } 
        }
        .nav-submenu {
            position: static;
            visibility: visible;
            opacity: 1;
            box-shadow: none;
            width: 100%;
            margin-top: 0;
            padding: 0;
            margin-left: 16px;
            display: none;
            transition: 0s;
            &__link {
                width: 100%;
                padding: 10px 0;
                border-bottom: 1px solid hsl(var(--black)/.08) !important;
            }
            &__item {
                &:hover {
                    background-color: transparent;
                    color: hsl(var(--main));
                }
                &::before {
                    display: none;
                }
            }
        }
    }
}
/* Mobile Menu Sidebar Start */