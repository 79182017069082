/* ========================= Download Section Css Start ====================== */
.download-wrapper {
	background-color: hsl(var(--section-bg));
	padding: 24px;
	border-radius: 16px;
    overflow-x: auto;
}

.download-item {
    position: relative;
    padding-bottom: 24px;
    margin-bottom: 24px;
    min-width: max-content;
    &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        &::before {
            display: none;
        }
    }
    &::before {
        position: absolute;
        content: "";
        width: calc(100% + 48px);
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: hsl(var(--gray-five));
        height: 1px;
    }
    &__thumb {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        overflow: hidden;
    }
}
/* ========================= Download Section Css End ====================== */