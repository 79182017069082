/* ========================= Earning Section Css Start ============================= */
.earning-card {
    background-color: hsl(var(--static-black));
    padding: 24px;
    border-radius: 8px;
    border: 1px solid hsl(var(--gray-five));
    box-shadow: var(--box-shadow);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__amount {
        border-color: hsl(var(--white)/.2) !important;
    }
}
/* ========================= Earning Section Css End ============================= */
