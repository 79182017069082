/* ======================== Breadcrumb Two Section Css End ===================== */
.breadcrumb-two {
    padding: 72px 0;
    @include md-screen {
        padding: 60px 0;
    }
}
.breadcrumb-content {
    &__item {
        @include md-screen {
            @include font-14; 
        }
    }
}

.breadcrumb-tab {
    @include msm-screen {
        margin-bottom: 24px;
    }
}

/* Social Icons Css Start */
.social-share {
    position: relative;
    &__button {
        color: hsl(var(--body-color));
    }
    &__icons {
        background-color: hsl(var(--white));
        padding: 12px;
        border-radius: 5px;
        position: absolute;
        right: 0;
        top: 100%;
        min-width: max-content;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.2));
        visibility: hidden;
        opacity: 0;
        margin-top: 16px;
        transition: 0.1s linear;
        &.show {
            visibility: visible;
            opacity: 1;
            margin-top: 6px;
        }
        &.left {
            right: auto;
            left: 0;
            &::before {
                left: 8px;
                right: auto;
            }
        }
        &::before {
            position: absolute;
            content: "";
            width: 14px;
            height: 14px;
            top: -7px;
            right: 8px;
            transform: rotate(45deg);
            background-color: hsl(var(--white));
            border-radius: 3px;
        }
    }
}

/* Social Icons Css End */
/* ======================== Breadcrumb Two Section Css End ===================== */
