/* =========================== Accordion Css start ============================= */ 
.common-accordion {
    .accordion-item {
        border: 1px solid hsl(var(--border-color));
        background-color: hsl(var(--white)) !important;
        border-radius: 8px;
        overflow: hidden;
        &:not(:last-child) {
            margin-bottom:20px;
        }
    }
    .accordion-header {
        line-height: 1;
    }
    .accordion-body {
        padding: 20px 30px;
        
        padding-top: 0;
        @include msm-screen {
            padding: 12px 20px;
            padding-top: 0;
        }
    }
    &:first-of-type .accordion-button.collapsed {
        border-radius: 5px;  
    }
    &:last-of-type .accordion-button.collapsed {
        border-radius: 5px;  
    }
    .accordion-button{
        color: var(--heading-color);
        @include font-18; 
        padding: 20px 30px;
        padding-right: 46px; 
        font-weight: 600;
        font-size: clampCal(16, 20);
        font-family: var(--heading-font);
        @include msm-screen {
            padding: 12px 20px;
            padding-right: 36px;
        }
        &::after {
            background-image: none;
        }
        &:focus {
            box-shadow: none;
        }
        &:not(.collapsed) {
            background-color: transparent !important;
            box-shadow: none;
            color: hsl(var(--main));
            &::after {
                background-image: none;
                color: hsl(var(--main)); ; 
            }
        }
        &[aria-expanded="true"], &[aria-expanded="false"] {
            &::after {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f106";
                color: hsl(var(--main));
                display: inline-block;
                position: absolute;
                @extend .top-center; 
                right: 30px;
                height: unset;
                @include font-16; 
                text-align: center;
                @include msm-screen {
                    right: 20px;
                }
            }
        }
        &[aria-expanded="false"] {
            &::after {
                content: "\f107";
                color: hsl(var(--heading-color));
            }
        }
    }
}
/* ================================= Accordion Css End =========================== */