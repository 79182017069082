/* =========================== Article Section Css Start ============================ */
.article-item-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
}

.article-item {
    display: flex;
    justify-content: space-between;
    gap: clampCal(16, 40);
    border: 1px solid hsl(var(--gray-five));
    border-left: 0;
    border-right: 0;

    @include sm-screen {
        min-width: max-content;
    }
    
    &:hover {
        .article-item {
            &__thumb {
                top: 16px;
                visibility: visible;
                opacity: 1;
                @include sm-screen {
                    top: 0;
                }
            }
        }
    }
    &__inner {
        gap: clampCal(24, 75);
        flex-grow: 1;
        padding: 32px 0;
        @include lg-screen {
            padding: 24px 0;
        }
        @include sm-screen {
            padding: 16px 0;
        }
    }
    &__center {
        gap: clampCal(24, 90);
    }
    &__content {
        max-width: 476px;
        @include md-screen {
            max-width: 330px;
        }
    }
    &__top {
        gap: clampCal(8, 24);
        margin-bottom: clampCal(12, 24);
    }
    &__tag {
        padding: 4px 16px;
        border-radius: 50px;
        border: 1px solid hsl(var(--gray-five));
        color: hsl(var(--black-three));
        &:hover {
            border-color: hsl(var(--main));
            color: hsl(var(--main));
        }
    }
    &__thumb {
        max-width: 300px;
        max-height: 190px;
        border-radius: 8px;
        overflow: hidden;
        position: absolute;
        right: 40px;
        z-index: 1;
        top: 32px;
        transform: rotate(-15deg);
        visibility: hidden;
        opacity: 0;
        transition: .2s linear;
        @include sm-screen {
            position: relative;
            visibility: visible;
            opacity: 1;
            top: 0px;
            transform: rotate(0);
            right: 0;
        }
    }
    &__end {
        margin-right: 24px;
        margin-top: clampCal(16, 40);
        @include lg-screen {
            margin-right: 0;
        }
    }
}

.user-info {
    &__thumb {
        width: 86px;
        height: 86px;
        border-radius: 50%;
        overflow: hidden;
    }
}
/* =========================== Article Section Css End ============================ */